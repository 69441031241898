import { GetId } from './../../protobuf/notify_pb.d';
import { getVehiclesLocations } from 'store/actions';
import { vehicleAction } from "components/charts/cell-renders";
import { Vehicles } from "pages";
import {FirmwareItem, ModelItem} from "protobuf/vehicle_service_pb";
import { Vehicle, VehiclesState } from "types/store";
import { getFullName, getRandomString } from "../helpers";
import {VEHICLE_SERVICE_STATUS} from "../mapped-data";
import {getDateFromSeconds} from "utils/helpers";

export function parseVehicles(list?: { licensePlate: string; id: string }[]) {
  if (list) {
    return list.map((vehicle) => ({
      label: vehicle.licensePlate,
      value: vehicle.id,
    }));
  }

  return [];
}


export function parseFirmwares(list?:  FirmwareItem.AsObject[]) {
  if (list) {
   return list.map((firmware) => ({
      value: firmware.id,
      label: firmware.versionName,
  }));
  }


  return [];
}

export function parseVehicleMaps(list?: Vehicle[]) {
  if (list) {
   return list.filter((Vehicle)=>Vehicle);
  }

  return [];
}

export function parseVehiclesTable(
  list?: VehiclesState["vehicles"]
) {
  if (list) {
    const data = list.map((vehicle, index) => ({
      key: index,
      vehicle_id: vehicle.licensePlate,
      partner: vehicle.ownerOrgName,
      model: vehicle.model,
      functional_status: vehicle.status,
      vehicle_functional_status: vehicle.vehicleFunctionalStatus,
      utilization_status: vehicle.status,
      driver_name: vehicle.assignee?.map(u => getFullName(u.firstName, u.lastName)),
      driver_id: vehicle.assignee?.map((user) =>
        getFullName(user.firstName, user.lastName)
      ),
      iot_id: vehicle.deviceIotId,
      zone: vehicle.zone_name,
      battery: vehicle.battery,
      vin: vehicle.vin,
      vehicle_service_status: vehicle.vehicleServiceStatus > 0 ? VEHICLE_SERVICE_STATUS[vehicle.vehicleServiceStatus] : "Operational",
      location_updated_time: vehicle.locationUpdatedAt,
      checkout_by_name: vehicle.checkedOutByName,
      is_billing_active:vehicle.isRidCreated ? "Active" : "Inactive",
      action: "",
      raw: vehicle,
    }));

    return data
  }

  return [];
}

export function parseModels(list?: ModelItem.AsObject[]) {
  if (list) {
    return list.map((model) => ({ label: model.name, value: model.id }));
  }

  return [];
}

const getFormattedDate = (inputString: string): string => {
  // Attempt to parse the date string into a Date object
  const inputDate = new Date(inputString);

  // Check if the date is valid
  if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date string provided.');
  }

  // Define display options for toLocaleString
  const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
  };

  // Return the formatted date string according to specified locale and options
  return inputDate.toLocaleString('en-US', options);
};
export function parseVehicleLocationTable(
  list?: VehiclesState["vehicleLogs"]
){
  if (list) {
     console.log("TimeLog:" +list[0].getDateAndTime);
      const data = list.map((getVehiclesLogs, index) => ({
          key: index,
          id: getVehiclesLogs.getVehicleId(),
          latitude: getVehiclesLogs.getLatitude(),
          longitude: getVehiclesLogs.getLongitude(),
          location_time: getFormattedDate(getDateFromSeconds(getVehiclesLogs.getDateAndTime()).toLocaleString()),
      }));
      return data
  }

  return [];
}