import { useMemo, useCallback } from "react";
import { Typography, Dropdown, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { setModalName, setSelectedUser } from "store/actions";

import {
  starIcon,
  clipboardIcon,
  halmetIcon,
  calendarIcon,
  bikeIcon,
  editIcon,
  mailIcon,
  phoneIcon,
} from "svgs";
import { usersTableData } from "./__mock__";
import { Tag } from "components/base";
import { classNames, getFullName } from "utils/helpers";
import { DEFAULT_AVATER, ROUTES } from "utils/constants";
import { UserDataItem, UsersState } from "types";
import DropdownAction from "./dropdown-action";
import {AppState} from "../../../store";

const { Text } = Typography;

export function renderEmptyContent(value: any) {
  return {
    children: value,
    props: {
      colSpan: 0,
    },
  };
}

export function userIconCell(
  value: string,
  row: typeof usersTableData[number] & { raw: UserDataItem }
) {
  return (
    <figure className="user-icon-container">
      <img
        src={row.raw?.profilePhotoLink || DEFAULT_AVATER}
        className="user-image"
        alt="user pic"
      />
    </figure>
  );
}

export function UserIconCellTable(
  value: string,
  row: typeof usersTableData[number] & { raw: UserDataItem },
  index: number
) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  const userData: UsersState["selectedUser"] = {
    id: row?.raw?.id,
    name: getFullName(row?.raw?.firstName, row?.raw?.lastName),
    phone: row?.raw?.phoneNumber,
    email: row?.raw?.email,
    imgUrl: row?.raw?.profilePhotoLink,
    type: row?.raw?.type,
    documentId: row?.raw?.documentId,
    lincenceNo: row?.raw?.licenseNo,
    vehicle: row?.raw?.vehicle,
    document: row?.raw?.document,
    documentLink: row?.raw?.documentLink,
    documentName: row?.raw?.documentName,
    documentType: row?.raw?.documentExt,
    organization_id: row?.raw?.organizationId,
    organization_name: row?.raw?.organizationName,
  };


  const onClickUserDetail = useCallback(() => {
    dispatch(setSelectedUser(userData));
    history.push(ROUTES.user_detail);
  }, [history, userData, dispatch]);

  const onEditUser = useCallback(() => {
    dispatch(setSelectedUser(userData));
    dispatch(setModalName("edit_user"));
  }, [userData, dispatch]);

  const userDetails = useMemo(
    () => (
      <article className="user-details-card"  onClick={onClickUserDetail}>
        <figure className="user-image-wrapper">
          <img
            className="user-image image-rounded"
            src={userData.imgUrl || DEFAULT_AVATER}
            alt="user pic"
          />
        </figure>
        <section className="user-details">
          <h1 className="user-name">{userData.name}</h1>
          <span className="user-contact-info">
            {phoneIcon} {userData.phone}
          </span>
          <span className="user-contact-info">
            {mailIcon} <span className="--email">{userData.email}</span>
          </span>
          {/* <a className="user-details-cta" onClick={onClickUserDetail}>
            User Details
          </a> */}
        </section>

        {/* <span className="user-edit-icon" onClick={onEditUser}>
          {editIcon}
        </span> */}
      </article>
    ),
    [userData]
  );

  return (
    <Dropdown
      overlay={userDetails}
      placement="bottomRight"
      overlayClassName="overlay-user-details-card">
      <figure className="user-icon-container-table">
        <img
          width="22px"
          height="22px"
          className="image-rounded"
          src={row?.raw.profilePhotoLink || DEFAULT_AVATER}
          alt="user pic"
        />
        <span className="user-name">{row.driver_name}</span>
      </figure>
    </Dropdown>
  );
}

export function assignedVehicles(
  value: string,
  row: typeof usersTableData[number]

) {
  const assignedVehiclesList = row.vehicle_id.join(",");
  return (
    <figure className="list-text-container" style={{ marginRight: 6 }}>
      {row.vehicle_id
        ?.filter((data, index) => index < 2)
        .map((licence) => (
          <Tag customClasses="vehicle-id-tag-list --fixed-width --gray" style={{ marginRight: 6 }}>
            {licence}
          </Tag>
        ))}
      {row.vehicle_id.length > 2 && (
        <Tooltip title={assignedVehiclesList} color="blue">
          <span className="count-box">
            {`+${row.vehicle_id.length - 2}`}
          </span>
        </Tooltip>
      )}
    </figure>
  );
}
export function safetyRating(
  value: string,
  row: typeof usersTableData[number]
) {
  const classes = classNames({
    yellowstarIcon: row.safety_rating >= "3",
    redstarIcon: row.safety_rating < "3",
  });

  return (
    <span>
      {row.safety_rating}
      <span className={classes}>{starIcon}</span>
    </span>
  );
}

export function userStatus(value: string, row: typeof usersTableData[number]) {
  const status = row.user_status;
  const classes = classNames({
    "--green": status === "Active",
    "--gray": status === "In-active",
    "--red": status === "Blocked",
  });

  return (
    <Tag customClasses={`status-tag --fixed-width ${classes}`}>{status}</Tag>
  );
}

export function ActionMenu(
  value: string,
  row: typeof usersTableData[number] & { raw: UserDataItem }
) {
  const dispatch = useDispatch();
  const userData: UsersState["selectedUser"] = {
    id: row?.raw?.id,
    name: getFullName(row?.raw?.firstName, row?.raw?.lastName),
    phone: row?.raw?.phoneNumber,
    email: row?.raw?.email,
    imgUrl: row?.raw?.profilePhotoLink,
    type: row?.raw?.type,
    documentId: row?.raw?.documentId,
    lincenceNo: row?.raw?.licenseNo,
    vehicle: row?.raw?.vehicle,
    document: row?.raw?.document,
    documentLink: row?.raw?.documentLink,
    documentName: row?.raw?.documentName,
    documentType: row?.raw?.documentExt,
    organization_id: row?.raw?.organizationId,
    organization_name: row?.raw?.organizationName
  };
  const onEditUser = useCallback((e) => {
    e.stopPropagation();
    dispatch(setSelectedUser(userData));
    dispatch(setModalName("edit_user"));
  }, [userData, dispatch]);
  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  return (
      <section className="table-action-container show-on-hover">
        {userType != 6 &&
      <span className="edit-icon icon" onClick={onEditUser}>
        {editIcon}
      </span>}
        {userType != 6 && <DropdownAction user={row.raw}/> }
      </section>
  );
}

export function userDetailCell(
  value: string,
  row: typeof usersTableData[number]
) {
  const classes = classNames({
    yellowstarIcon: row.safety_rating >= "3",
    redstarIcon: row.safety_rating < "3",
  });
  const assignedVehiclesList = row.vehicle_id.join(", ");
  return (
    <p className="user-detail">
      <Text className="user-id" type="secondary">
        <strong>{row.driver_name}</strong>
      </Text>
      <Text type="secondary" className="user-detail-wrapper">
        <figure className="list-text-container">
          <span className="icon --calendar">{calendarIcon}</span>
          <figcaption>
            <span>10:23 pm</span>
            <span>-</span>
            <span>11:23 pm</span>
          </figcaption>
        </figure>
        <figure className="list-text-container">
          <span className="icon --bike">{bikeIcon}</span>
          <figcaption>
            {row.vehicle_id
              ?.filter((data, index) => index < 3)
              .map((licence) => (
                <Tag customClasses="vehicle-id-tag-list --fixed-width --gray">
                  {licence}
                </Tag>
              ))}
            {row.vehicle_id.length > 3 && (
              <Tooltip title={assignedVehiclesList} color="blue">
                <span className="count-box">
                  {`+${row.vehicle_id.length - 3}`}
                </span>
              </Tooltip>
            )}
          </figcaption>
        </figure>

        <figure className="list-text-container">
          <span className="icon">{halmetIcon}</span>
          <figcaption>
            <span>
              {row.safety_rating}
              <span className={classes}>{starIcon}</span>
              Safety
            </span>
          </figcaption>
        </figure>

        <figure className="list-text-container">
          <span className="icon">{clipboardIcon}</span>
          <figcaption>
            <span>{row.task_completed}</span>
            <span>Completed</span>
          </figcaption>
        </figure>
      </Text>
    </p>
  );
}
