import { UserTileProps } from "types";

export default function UserTile(props: UserTileProps) {
  const { icon, title, value, link } = props;
  return (
    <article className="tile-wrapper">
      <section className="tile-header">
        {icon}
        {value}
        {link}
      </section>
      <span className="tile-title">{title}</span>
    </article>
  );
}
