import { format } from "date-fns";

import { UserItem } from "protobuf/user_service_pb";
import { FULL_DATE_FORMAT } from "../constants";
import { getDateFromSeconds, getFullName, getRandomString } from "../helpers";
import { USER_TYPE } from "utils/mapped-data";
import { UserDataItem } from "types";

export function parseUsers(list?: UserItem.AsObject[]) {
  if (list) {
    return list.map((user) => ({
      label: getFullName(user.firstName, user.lastName),
      value: user.id,
    }));
  }

  return [];
}

export function parseUsersTable(search: string, list?: UserDataItem[]) {
  if (list) {
    console.log("client admins list : ", list)
    const data = list.map((user, index) => ({
      key: index,
      driver_name: getFullName(user.firstName, user.lastName),
      user_id: user.serialId,
      document_id: user.documentId,
      phone_no: user.phoneNumber,
      role: USER_TYPE[user.type],
      added_on: format(getDateFromSeconds(user.addedAt), FULL_DATE_FORMAT),
      added_by: user.createdBy,
      vehicle_id: user.vehicle?.map((v) => v.vehicleLicensePlate) || "-",
      date_and_time: "",
      today_shift: getRandomString([
        " 12:00 pm - 08:00 pm",
        "01:00 pm - 09:00 pm",
        "12:00 pm - 08:00 pm",
        "01:00 pm - 09:00 pm",
      ]),
      safety_rating: getRandomString(["1", "2", "3", "4", "5"]),
      user_status: getRandomString(["Active", "In-active", "Blocked"]),
      active_tasks: getRandomString(["1", "2", "3", "4", "5"]),
      task_completed: getRandomString(["1", "2", "3", "4", "5"]),
      organization_name : user.organizationName,
      rider_score: parseFloat(user.riderScores).toFixed(2) || 0,
      action: "",
      raw: user,
    }));

    return data.filter((user) => {
      return user.driver_name.toLowerCase().includes(search.toLowerCase());
    });
  }

  return [];
}
